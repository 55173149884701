@import '../styleConsts';

.button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--primary-color);

  @media (max-width: $small-screen-width) {
    font-size: 12px;
    padding: 5px 10px;
  }

  &.info {
    background-color: var(--info-color);
    color: var(--primary-color);
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--info-hover-color);
    }
  }

  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }
}