@import '../../../styleConsts';

.productSection {
  background-color: var(--secondary-bg-color);

  .title {
    font-weight: bold;
    margin-bottom: 12px;
    color: var(--primary-color);
    text-align: center;
  }

  .productDetailsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: $portrait-screen-width) {
      grid-template-columns: 1fr;
    }
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .subTitle {
      font-size: 1.5rem;
      margin: 20px 0 10px;
    }

    .paragraph {
      font-size: 1rem;
      color: var(--primary-color);
    }

    .bulletDescription {
      color: var(--disabled-color);
      font-size: 24px;
      font-weight: bold;
    }
  }

  .rightContent {
    display: flex;
    justify-content: center;
    align-items: center;

    .coverImage {
      width: 100%;
      max-width: 400px;
      height: auto;
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: $portrait-screen-width) {
    .leftContent {
      .subTitle {
        font-size: 1.2rem;
      }

      .paragraph {
        font-size: 0.9rem;
      }
    }

    .rightContent {
      .coverImage {
        max-width: 100%;
      }
    }
  }
}
