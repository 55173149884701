@import '../styleConsts';

.sectionDivider {
  height: 150px;
  background-repeat: no-repeat;
  background-size: 70% 100%;
  background-position: center;

  @media (max-width: $medium-screen-width) {
    height: 120px;
    background-size: 70% 70%;
  }

  @media (max-width: $small-screen-width) {
    height: 70px;
    background-size: 70% 70%;
  }
}