$phone-screen-width: 400px;
$small-screen-width: 600px;
$portrait-screen-width: 900px;
$medium-screen-width: 1000px;

:root {
  --primary-color: #ffffff;
  --info-color: #fa5723;
  --info-hover-color: #d53c0b;
  --secondary-info-color: #37211b;
  --disabled-color: #8a8b8c;
  --indication-color: #7b8aa8;
  --main-bg-color: #0a0b10;
  --secondary-bg-color: #11161a;
  --content-box-bg-color: #0a0b0e;
  --error-color: #9b0038;
}