@import '../../../styleConsts';

.insightsSection {
  background-color: var(--secondary-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;

    .insightItem {
      transition: transform 0.3s ease-in-out;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      &:hover {
        transform: scale(1.05);
      }

      .thumbnail {
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .insightItemTitle {
        font-weight: bold;
      }
    }

    @media (max-width: $small-screen-width) {
      flex-direction: column;

      .insightItem {
        width: 100%;
      }
    }
  }

  @media (max-width: $small-screen-width) {
  }
}
