@import '../../../styleConsts';

.technologySection {
  background: linear-gradient(to bottom, var(--secondary-bg-color), var(--main-bg-color)) !important;
  margin: 0 !important;
  border-radius: 0 !important;

  .title {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 2rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: $portrait-screen-width) {
      grid-template-columns: 1fr;
    }

    .descriptionBox {
      background-color: var(--content-box-bg-color);
      padding: 1.5rem;
      flex-direction: column;
      border-top: 1px solid var(--info-color);
      border-left: 1px solid var(--info-color);
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}