@import './styleConsts';

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Futura, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  overflow-x: hidden;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: var(--main-bg-color);
  color: var(--primary-color);

  h1 {
    font-size: 30px;
  }

  h2 {
    margin: 0;
    font-size: 28px;
  }

  h3 {
    margin: 0;
    font-size: 22px;
  }

  h4 {
    margin: 0;
    font-size: 16px;
  }

  h5 {
    margin: 0;
    font-size: 14px;
  }

  @media (max-width: $small-screen-width) {
    h1 {
      font-size: 26px;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 14px;
    }

    h5 {
      font-size: 12px;
    }
  }
}
