@import '../../styleConsts';

.main {
  section {
    padding: 36px 48px 48px;
    border-radius: 30px;
    margin: 50px;
    @media (max-width: $medium-screen-width) {
      margin: 30px;
    }
    @media (max-width: $small-screen-width) {
      padding: 24px 36px 36px;
      margin: 15px;
    }
  }
}
