@import '../styleConsts';

.textWithNumberIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  .roundedNumber {
    background-color: var(--secondary-info-color);
    color: var(--info-color);
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-align: center;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    margin-right: 20px;
  }
}