@import '../../styleConsts';

.header {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  min-height: 450px;

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    opacity: 0.3;
  }

  .headerContent {
    position: relative;
    z-index: 1;
    padding: 36px 48px 48px;

    .textsWrapper {
      margin-top: 50px;
      width: 60%;

      .title {
        font-weight: bold;
        margin-bottom: 40px;
      }
    }

    @media (max-width: $small-screen-width) {
      padding: 24px 36px 36px;

      .textsWrapper {
        width: auto;
      }
    }
  }
}