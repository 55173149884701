@import '../styleConsts';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .logo {
    img {
      width: 20vw;
      min-width: 200px;
      margin-bottom: 10px;
    }
  }

  .socialLinks {
    a {
      img {
        width: 50px;
        margin: 0 6px;
      }
    }
  }

  .contact {
    margin: 0 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contactContentWrapper {
      margin-top: 15px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: start;
      align-self: stretch;

      .title {
        color: #707d98;
        margin-bottom: 10px;
      }

      .contactContent {
        text-decoration: none;
        color: #babdc2;
        margin-bottom: 10px;
      }

      .contactDescription {
        color: #474848;
      }
    }

    @media (max-width: $portrait-screen-width) {
      flex-direction: column;

      .contactContentWrapper {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
