@import '../../../styleConsts';

.exploreSection {
  background-color: var(--secondary-bg-color);
  display: flex;

  .content {
    width: 50%;
  }

  @media (max-width: $portrait-screen-width) {
    flex-direction: column;
    .content {
      width: 100%;
    }

    .leftContent {
      order: 2;
    }

    .rightContent {
      order: 1;
      padding-bottom: 20px;
    }
  }

  .leftContent {
    .exploreImage {
      width: 100%;
      height: auto;
    }
  }

  .rightContent {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .heading {
      margin-bottom: 10px;
    }

    .descriptionBox {
      margin: 12px 0;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateX(8px);
      }
    }
  }

  @media (max-width: $small-screen-width) {
    .leftContent {
      .subTitle {
        font-size: 1.2rem;
      }

      .paragraph {
        font-size: 0.9rem;
      }
    }

    .rightContent {
      .coverImage {
        max-width: 100%;
      }
    }
  }
}
