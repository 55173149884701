.descriptionBox {
  background-color: var(----content-box-bg-color);
  border-radius: 15px;
  align-self: stretch;
  display: flex;
  align-items: start;
  justify-content: left;

  .icon {
    background-color: var(--secondary-info-color);
    border-radius: 6px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 10px;

    svg {
      path {
        fill: var(--info-color);
      }
    }
  }

  .content {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .description {
      font-size: 14px;
    }
  }
}