@import '../styleConsts';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 10;

  .logo {
    img {
      width: 25vw;
      max-width: 250px;
      min-width: 180px;
    }
  }
}